<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(
      color="secondary",
      x-small,
      outlined,
      @click="load",
      v-on="on",
      :loading="opening",
      v-if="visible"
    ) Contact Seller
  v-form(@submit.prevent="post")
    v-card(flat)
      v-card-title Contact Seller
      v-card-text
        .s-vstack
          Messages(:conversation="conversation")
          div(v-if="showForm")
            .caption Please understand that restaurant could be busy and not reply promptly. Please keep the conversation concise and to the point. That can help restaurant staff work it out faster. A phone call could be an alternative if the request is urgent.
            v-text-field(v-model="name", label="Name", outlined)
            v-textarea(
              clearable,
              no-resize,
              rows="2",
              outlined,
              v-model="content",
              label="Message",
              :counter="300",
              :disabled="!postable"
            )
          .caption.text--secondary(v-else) No longer available to contact seller online. Please call the restaurant directly if you need help.
      v-card-actions
        v-btn(
          color="secondary",
          text,
          invalid="!postable",
          v-if="showForm",
          type="submit",
          :loading="loading"
        ) Send
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import Messages from "./Messages/index";

export default {
  components: { Messages },
  props: {
    order: { type: Object, default: null },
  },
  data() {
    return {
      opening: false,
      loading: false,
      dialog: false,
      name: "",
      content: "",
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    ...mapGetters(["conversation"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    isBiz() {
      return this.app_name === "Selflane Business";
    },
    // customer can contact seller up to 1 day after the order is needed
    cutoff() {
      const days = this.isInternal ? 7 : 1;
      return moment().subtract(days, "days").startOf("day").valueOf();
    },
    showForm() {
      return this.order && this.order.needed >= this.cutoff;
    },
    visible() {
      if (this.isInternal) return true;
      return this.order && this.order.needed >= this.cutoff;
    },
    postable() {
      if (!this.conversation) return true;
      return this.conversation.status == "open";
    },
    channel() {
      if (this.isInternal) return "selflane";
      if (this.isBiz) return "backoffice";
      return "customer";
    },
  },
  methods: {
    ...mapActions(["setConversation"]),
    async load() {
      const params = { order_id: this.order._id };
      this.opening = true;
      try {
        const result = await this.$api.orderAction.conversation.retrieve(
          params
        );
        this.setConversation(result);
        this.dialog = true;
        if (this.isInternal) {
          this.name = "Selflane";
        } else if (this.isBiz) {
          this.name = "";
        } else {
          this.name = this.order?.customer?.name;
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.opening = false;
    },
    validate() {
      this.content = this.content?.trim();
      if (!this.content) {
        throw new Error("Please enter a message.");
      }
      if (this.content.length < 2 || this.content.length > 300) {
        throw new Error("Please enter a message between 2 and 300 characters.");
      }
    },
    async post() {
      this.loading = true;
      try {
        this.validate();
        const params = {
          order_id: this.order._id,
          message: {
            name: this.name || "customer",
            content: this.content,
            channel: this.channel,
          },
        };
        const result = await this.$api.orderAction.conversation.custom(
          "post",
          params
        );
        this.setConversation(result);
        this.content = "";
        this.$toast.success("Posted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>