import { render, staticRenderFns } from "./index.vue?vue&type=template&id=11ddd01c&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__rhe5kvdr5vkz7m642zqouvtsfe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports